<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/workOver">加班申请记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
    <el-form-item label="所属单位" prop="companyId">
        <el-select-tree
          size="mini"
          :props="props"
          :options="treeData"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>&nbsp;
          <el-checkbox v-model="queryModel.subordinate"
            >是否包含下级单位</el-checkbox
          >
      </el-form-item>
      <el-form-item label="申请人姓名" prop="personName">
        <el-input type="text" size="mini" v-model="queryModel.personName"></el-input>
      </el-form-item>
      <el-form-item label="审批状态" prop="status">
        <el-select
          v-model="queryModel.status"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:100px"
        >
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间" prop="requestTimeRange">
        <el-date-picker
            v-model="queryModel.requestTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核时间" prop="auditTimeRange">
        <el-date-picker
            v-model="queryModel.auditTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <!-- <el-button type="success" size="small" plain icon="el-icon-download" @click="exportXls()">批量导出</el-button> -->
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-form label-position="left" class="demo-table-expand">
            <el-form-item label="开始时间:">
              <span>{{row.startTime }}</span>
            </el-form-item>
            <el-form-item label="结束时间:">
              <span>{{row.endTime }}</span>
            </el-form-item>
            <el-form-item label="加班时长:">
              <span>{{row.hours }}</span>
            </el-form-item>
            <el-form-item label="加班原因:">
              <span>{{row.reason }}</span>
            </el-form-item>
            <el-form-item label="审核意见:">
              <span>{{row.approvalContent }}</span>
            </el-form-item>
            <el-form-item label="审核时间:">
              <span>{{row.approvalTime }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申请时间" :sortable="false" width="180"></el-table-column>
      <el-table-column
        prop="personName"
        label="申请人"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="单位"
        width="180"
      ></el-table-column>
      
      <el-table-column
        prop="approvalPersonName"
        label="审批人"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="statusN"
        label="状态"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="approvalTime"
        label="审核时间"
        width="180"
      ></el-table-column>
      <el-table-column prop="remark" label="备注" width="180"></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <workOver-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></workOver-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import workOverApi from "@/api/base/workOver";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseWorkOverList",
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
        personName: "",
        status:"",
        requestTimeRange: ['',''],
        auditTimeRange: ['',''],
        subordinate:false
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      statusList: [
        {id: 0, name: "待处理"},
        {id: 1, name: "已同意"},
        {id: 2, name: "已拒绝"}
      ],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      
    };
  },
  created() {
    this.loadTree();
    this.changePage(1);
  },
  methods: {
    showStatusName(status) {
      var list = this.statusList.filter(item=>item.id == status);

      if(list.length>0){
        return list[0].name;
      }
      else{
        return "";
      }
    },
    loadTree() {
      companyInfoApi.treeList().then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if(exportFlag!=null){
        formData.append("exportFlag", exportFlag);
        formData.append("pageSize", 10000);
      }
      else{
        formData.append("pageSize", self.pageSize);
      }

      if(self.queryModel.companyId != null){
        formData.append("companyId", self.queryModel.companyId);
      }

      formData.append("subordinate", self.queryModel.subordinate);
      
      formData.append("personName", self.queryModel.personName);
      formData.append("status", self.queryModel.status);
      if(self.queryModel.requestTimeRange[0] != null){
        formData.append("startTime", self.queryModel.requestTimeRange[0]);
      }

      if(self.queryModel.requestTimeRange[1] != null){
        formData.append("endTime", self.queryModel.requestTimeRange[1]);
      }

      if(self.queryModel.auditTimeRange[0] != null){
        formData.append("auditStartTime", self.queryModel.auditTimeRange[0]);
      }

      if(self.queryModel.auditTimeRange[1] != null){
        formData.append("auditEndTime", self.queryModel.auditTimeRange[1]);
      }

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      workOverApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          workOverApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        workOverApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    exportXls() {
      this.changePage(1,true);
    }
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "el-select-tree": SelectTree
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>